<template>
  <b-card-code title="Create product">
    <b-card-text>
      <h5 class="text-primary font-weight-bolder">
        <feather-icon icon="FileTextIcon" />
        Please enter product details
      </h5>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Item code"
            >
              <validation-provider
                #default="{ errors }"
                name="item"
              >
                <b-form-input
                  v-model="product.item_code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Item"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="EAN"
              label-for="ean"
            >
              <validation-provider
                #default="{ errors }"
                name="ean"
              >
                <b-form-input
                  id="ean"
                  v-model="product.ean"
                  placeholder="ean"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Description"
              label-for="ean"
            >
              <validation-provider
                #default="{ errors }"
                name="ean"
              >
                <b-form-input
                  id="ean"
                  v-model="product.description"
                  placeholder="ean"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="mt-2 mb-1"
          >
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="ArrowDownCircleIcon" />
              Please enter import details
            </h5>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="NBR PCS / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="nbr_import"
              >
                <b-form-input
                  v-model="product.nbr_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nbr_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="NW / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="nw_import"
              >
                <b-form-input
                  v-model="product.nw_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nw_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="GW / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="gw_import"
              >
                <b-form-input
                  v-model="product.gw_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="gw_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="CBM / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="cbm_import"
              >
                <b-form-input
                  v-model="product.cbm_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="cbm_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Cost price / PCS"
            >
              <validation-provider
                #default="{ errors }"
                name="pa_import"
              >
                <b-form-input
                  v-model="product.pa_pcs_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="pa_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Cost price / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="pctn_import"
              >
                <b-form-input
                  :value="pa_ctn_import"
                  :state="errors.length > 0 ? false : null"
                  placeholder="pctn_import"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="mt-2 mb-1"
          >
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="ArrowUpCircleIcon" />
              Please enter export details
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="NBR PCS / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="nbr_export"
              >
                <b-form-input
                  v-model="product.nbr_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nbr_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="NW"
            >
              <validation-provider
                #default="{ errors }"
                name="nw_export"
              >
                <b-form-input
                  v-model="product.nw_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nw_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="GW"
            >
              <validation-provider
                #default="{ errors }"
                name="gw_export"
              >
                <b-form-input
                  v-model="product.gw_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="gw_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group
              label="CBM / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="cbm_export"
              >
                <b-form-input
                  v-model="product.cbm_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="cbm_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Sales price / PCS"
            >
              <validation-provider
                #default="{ errors }"
                name="pv_export"
              >
                <b-form-input
                  v-model="product.pv_pcs_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="pv_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Sales price / CTN"
            >
              <validation-provider
                #default="{ errors }"
                name="pctn_export"
              >
                <b-form-input
                  :value="pv_ctn_export"
                  :state="errors.length > 0 ? false : null"
                  placeholder="pctn_export"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-1"
          >
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addProduct"
            >
              <b-spinner
                v-if="load === 'true'"
                small
              />
              Add
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
  },
  data() {
    return {
      load: 'false',
      product: {
        item_code: '',
        ean: '',
        stock: 0,
        description: '',
        nbr_import: 0,
        nw_import: 0.00,
        gw_import: 0.00,
        cbm_import: 0.000,
        pa_pcs_import: 0.00,
        nbr_export: 0,
        nw_export: 0.00,
        gw_export: 0.00,
        cbm_export: 0.000,
        pv_pcs_export: 0.00,
      },
      required,
    }
  },
  computed: {
    pa_ctn_import() {
      return (this.product.pa_pcs_import * this.product.nbr_import).toFixed(2)
    },
    pv_ctn_export() {
      return (this.product.pv_pcs_export * this.product.nbr_export).toFixed(2)
    },
  },
  methods: {
    async addProduct() {
      this.load = 'true'

      this.product.pa_ctn_import = (this.product.pa_pcs_import * this.product.nbr_import).toFixed(2)
      this.product.pv_ctn_export = (this.product.pv_pcs_export * this.product.nbr_export).toFixed(2)

      try {
        await axiosIns.post('/products/create/', {
          item_code: this.product.item_code,
          ean: this.product.ean,
          stock: this.product.stock,
          description: this.product.description,
          nbr_import: this.product.nbr_import,
          nw_import: this.product.nw_import,
          gw_import: this.product.gw_import,
          cbm_import: this.product.cbm_import,
          pa_pcs_import: this.product.pa_pcs_import,
          pa_ctn_import: this.pa_ctn_import,
          nbr_export: this.product.nbr_export,
          nw_export: this.product.nw_export,
          gw_export: this.product.gw_export,
          cbm_export: this.product.cbm_export,
          pv_pcs_export: this.product.pv_pcs_export,
          pv_ctn_export: this.pv_ctn_export,
        })
        this.load = 'false'
        this.$router.push('/products/')
        this.showToast('success', 'top-center', 'product successfully created ')
      } catch (error) {
        this.load = 'false'
        this.showToast('danger', 'top-center', error.toString())
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
