var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Create product"}},[_c('b-card-text',[_c('h5',{staticClass:"text-primary font-weight-bolder"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" Please enter product details ")],1)]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Item code"}},[_c('validation-provider',{attrs:{"name":"item"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Item"},model:{value:(_vm.product.item_code),callback:function ($$v) {_vm.$set(_vm.product, "item_code", $$v)},expression:"product.item_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"EAN","label-for":"ean"}},[_c('validation-provider',{attrs:{"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ean","placeholder":"ean","state":errors.length > 0 ? false : null},model:{value:(_vm.product.ean),callback:function ($$v) {_vm.$set(_vm.product, "ean", $$v)},expression:"product.ean"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"ean"}},[_c('validation-provider',{attrs:{"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ean","placeholder":"ean","state":errors.length > 0 ? false : null},model:{value:(_vm.product.description),callback:function ($$v) {_vm.$set(_vm.product, "description", $$v)},expression:"product.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2 mb-1",attrs:{"md":"12"}},[_c('h5',{staticClass:"text-primary font-weight-bolder"},[_c('feather-icon',{attrs:{"icon":"ArrowDownCircleIcon"}}),_vm._v(" Please enter import details ")],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NBR PCS / CTN"}},[_c('validation-provider',{attrs:{"name":"nbr_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"nbr_import","type":"number"},model:{value:(_vm.product.nbr_import),callback:function ($$v) {_vm.$set(_vm.product, "nbr_import", $$v)},expression:"product.nbr_import"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"NW / CTN"}},[_c('validation-provider',{attrs:{"name":"nw_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"nw_import","type":"number"},model:{value:(_vm.product.nw_import),callback:function ($$v) {_vm.$set(_vm.product, "nw_import", $$v)},expression:"product.nw_import"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"GW / CTN"}},[_c('validation-provider',{attrs:{"name":"gw_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"gw_import","type":"number"},model:{value:(_vm.product.gw_import),callback:function ($$v) {_vm.$set(_vm.product, "gw_import", $$v)},expression:"product.gw_import"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"CBM / CTN"}},[_c('validation-provider',{attrs:{"name":"cbm_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"cbm_import","type":"number"},model:{value:(_vm.product.cbm_import),callback:function ($$v) {_vm.$set(_vm.product, "cbm_import", $$v)},expression:"product.cbm_import"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cost price / PCS"}},[_c('validation-provider',{attrs:{"name":"pa_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"pa_import","type":"number"},model:{value:(_vm.product.pa_pcs_import),callback:function ($$v) {_vm.$set(_vm.product, "pa_pcs_import", $$v)},expression:"product.pa_pcs_import"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cost price / CTN"}},[_c('validation-provider',{attrs:{"name":"pctn_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.pa_ctn_import,"state":errors.length > 0 ? false : null,"placeholder":"pctn_import","type":"number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2 mb-1",attrs:{"md":"12"}},[_c('h5',{staticClass:"text-primary font-weight-bolder"},[_c('feather-icon',{attrs:{"icon":"ArrowUpCircleIcon"}}),_vm._v(" Please enter export details ")],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"NBR PCS / CTN"}},[_c('validation-provider',{attrs:{"name":"nbr_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"nbr_export","type":"number"},model:{value:(_vm.product.nbr_export),callback:function ($$v) {_vm.$set(_vm.product, "nbr_export", $$v)},expression:"product.nbr_export"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"NW"}},[_c('validation-provider',{attrs:{"name":"nw_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"nw_export","type":"number"},model:{value:(_vm.product.nw_export),callback:function ($$v) {_vm.$set(_vm.product, "nw_export", $$v)},expression:"product.nw_export"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"GW"}},[_c('validation-provider',{attrs:{"name":"gw_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"gw_export","type":"number"},model:{value:(_vm.product.gw_export),callback:function ($$v) {_vm.$set(_vm.product, "gw_export", $$v)},expression:"product.gw_export"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"CBM / CTN"}},[_c('validation-provider',{attrs:{"name":"cbm_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"cbm_export","type":"number"},model:{value:(_vm.product.cbm_export),callback:function ($$v) {_vm.$set(_vm.product, "cbm_export", $$v)},expression:"product.cbm_export"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sales price / PCS"}},[_c('validation-provider',{attrs:{"name":"pv_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"pv_export","type":"number"},model:{value:(_vm.product.pv_pcs_export),callback:function ($$v) {_vm.$set(_vm.product, "pv_pcs_export", $$v)},expression:"product.pv_pcs_export"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sales price / CTN"}},[_c('validation-provider',{attrs:{"name":"pctn_export"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"value":_vm.pv_ctn_export,"state":errors.length > 0 ? false : null,"placeholder":"pctn_export","type":"number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addProduct.apply(null, arguments)}}},[(_vm.load === 'true')?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Add ")],1),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }